import { FileDownload } from '@mui/icons-material';
import { Button, Link, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Typography, } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Company, DateFormat } from '../../model';
import FormattedDate from '../../components/formatted-date';
import { CompactTableCell } from '../../components/globals';
import { Link as RouterLink } from "react-router-dom";
import { routes } from "../../lib";
import { MailToButton } from "../../components/company";

export const PressPassFiles: FunctionComponent<{
  company: Company;
}> = ({ company }) => {
  const { t } = useTranslation();
  const WhichCompany = company.responsiblePerson?.companies?.filter(c => c.company.id == company.id)[0];

  return (
    <TableContainer>
      <Table size={"small"} stickyHeader={true}>
        <TableHead>
          <TableRow>
            <CompactTableCell width={50}>#</CompactTableCell>
            <TableCell>{t('Link')}</TableCell>
            <TableCell align={"center"}>{t('Download')} ¹</TableCell>
            <TableCell align={"center"}>{t('E-Mail')} ²</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {company.pressPassFiles &&
            company.pressPassFiles
              .map((row, index) => (
                <TableRow key={row.date}>
                  <CompactTableCell>
                    {index + 1}
                  </CompactTableCell>
                  <CompactTableCell align={'left'}>
                    <Link href={`${process.env.REACT_APP_UPLOADS_URL}/${row.filename}`} rel="noopener" target="_blank">
                      Link vom <FormattedDate date={row.date} format={DateFormat.DateTime}/>
                    </Link>
                  </CompactTableCell>
                  <CompactTableCell align={"center"}>
                    <Button
                      color="primary"
                      variant="contained"
                      target="_blank"
                      href={`${process.env.REACT_APP_UPLOADS_URL}/${row.filename}`}
                      startIcon={<FileDownload/>}
                    >
                      {t('Herunterladen')}
                    </Button>
                  </CompactTableCell>
                  <CompactTableCell align={"center"}>
                    <MailToButton membership={'VÖZ'} company={company} filename={`${process.env.REACT_APP_UPLOADS_URL}/${row.filename}`}/>
                    {' '}
                    <MailToButton membership={'ÖZV'} company={company} filename={`${process.env.REACT_APP_UPLOADS_URL}/${row.filename}`}/>
                  </CompactTableCell>
                </TableRow>
              ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <CompactTableCell>
              <Typography variant="body1" style={{ fontSize: 'small' }} color="text.primary">
                ¹ Links sind 30 Tage gültig.
              </Typography>
            </CompactTableCell>
            <CompactTableCell>
              {WhichCompany &&
                <Typography variant="body1" style={{ fontSize: 'small' }} color="text.primary">
                  ² wird an {t('PA-Veranwortliche/r')} {company.responsiblePerson && (
                  <Link
                    component={RouterLink}
                    to={routes.person(company.responsiblePerson.id)}
                  >
                  </Link>
                )} gesendet.
                </Typography>}
              {!WhichCompany &&
                <Typography variant="body1" style={{ fontSize: 'small' }} color="text.primary">
                  Es wurde noch kein PA-Verantwortlicher mit einer Verknüfung für diese <Link
                  component={RouterLink}
                  to={routes.company(company.id)}
                >
                  Firma
                </Link> angelegt.
                </Typography>
              }
            </CompactTableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};
