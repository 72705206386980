import { Button } from '@mui/material';
import React, { VoidFunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Company, Salutation } from '../../model';
import { AttachEmail } from "@mui/icons-material";

export const MailToButton: VoidFunctionComponent<{ membership: string, company: Company, filename: string }> = ({
                                                                                                                  membership,
                                                                                                                  company,
                                                                                                                  filename
                                                                                                                }) => {
  const { t } = useTranslation();
  const newLine = '%0D%0A';
  const salutation = company.responsiblePerson ? `${company.responsiblePerson.salutation && company.responsiblePerson.salutation == Salutation.Female ? 'Sehr geehrte Frau' : (company.responsiblePerson.salutation == Salutation.Male) ? 'Sehr geehrter Herr' : ''} ${company.responsiblePerson.lastName}` : 'Sehr geehrte Damen und Herren';
  const emailTo = company.responsiblePerson?.companies?.find(c => c.company.id === company.id)?.email || company.responsiblePerson?.email;

  const bodyText = `wie wir bereits mitgeteilt haben, erfolgt zweimal im Jahr (Jänner/Juni) eine Abfrage zur Aktualität der Presseausweis-Inhabenden und ihrer Bezugsberechtigung.${newLine}${newLine}Wir schicken Ihnen in der Anlage eine Aufstellung der aktuellen Presseausweis-Inhaber/-innen Ihres Mediums/Verlages und ersuchen um Prüfung und allfällige Aktualisierung. Bitte übermitteln Sie uns die von der Geschäftsführung unterzeichnete Übersicht bis 31. Jänner 2025.${newLine}${newLine}`;
  const bodyText2 = `${filename}${newLine}${newLine}Da die Qualität der Presseausweise, die vom Kuratorium für Presseausweise und dessen Trägerverbänden herausgegeben werden, maßgeblich davon bestimmt ist, dass die Inhaber der Presseausweise auch bezugsberechtigt sind, dürfen wir Sie auch zwischen unseren Abfragen mit folgenden Maßnahmen um Ihre Unterstützung bitten:${newLine}${newLine}`;
  const bodyText3 = `- Bitte melden Sie uns umgehend Verluste von Presseausweisen.${newLine}- Bitte melden Sie uns Austritte aus Ihrem Unternehmen, sofern es sich um Presseausweisinhaber handelt. Wir dürfen Sie auch darum bitten, den Presseausweis von Mitarbeiterinnen und Mitarbeitern, die das Unternehmen verlassen, möglichst zurückzufordern.${newLine}${newLine}`;
  const bodyText4 = ``;
  const oezvText = membership === 'ÖZV' ? `Bitte beachten Sie: Für die Verlängerung der Presseausweise wird weiterhin eine jährliche Bearbeitungsgebühr in Höhe von EUR 13,50 (zzgl. USt.) je Presseausweis in Rechnung gestellt. Diese Gebühr wird ebenfalls erhoben, wenn ein Duplikat des Presseausweises aufgrund eines Verlustes oder infolge notwendiger Änderungen der Angaben der Ausweisinhabenden erstellt werden muss.${newLine}${newLine}` : '';
  const bodyFooter = `Wir danken schon jetzt herzlich für Ihre Kooperation und stehen für Fragen gerne zur Verfügung,${newLine}liebe Grüße${newLine}Nadja Vaskovich`;
  return (
    <>
      {company && (
        <Button
          color="primary"
          variant="contained"
          target="_blank"
          href={`mailto:${emailTo ? emailTo : ''}?subject=Abfrage zur Aktualität der Presseausweis-Inhabenden und ihrer Bezugsberechtigung – Rückmeldung bis 31. Jänner 2025 erbeten - ${encodeURIComponent(company.name)}&body=${salutation},${newLine}${newLine}${bodyText}${bodyText2}${bodyText3}${oezvText}${bodyText4}${bodyFooter}`}
          startIcon={<AttachEmail/>}
        >
          {t('Senden ('.concat(membership).concat(')'))}
        </Button>
      )}
    </>
  );
};
